const setProfessional = function (professional) {
  return {
    type: 'SET_PROFESSIONAL',
    professional
  };
};

const getProfessional = function (professional) {
  return {
    type: 'GET_PROFESSIONAL',
    professional: {
      professional_id: '111111'
    }
  };
};


export { setProfessional, getProfessional };
