import { combineReducers } from 'redux';

// import booking from './booking/BookingReducers';
import items from './items/ItemsReducers';
import professional from './professional/ProfessionalReducers';

export default combineReducers({
  items,
  professional,
});
