/* Pages */
// import ErrorPage from '../containers/errorPage/ErrorPage';
import IRoute from '../models/route';

// AssessmentCompany
import AssessmentOverview from '../pages/private/assessment/overview/Overview';

// Company
import ScheduleOverview from '../pages/private/schedule/overview/Overview';

// Employee
import EmployeeOverview from '../pages/private/employee/overview/Overview';

// Meet
import MeetOverview from '../pages/private/meet/overview/Overview';

// Session
import SessionOverview from '../pages/private/session/overview/Overview';
import SessionRecord from '../pages/private/session/record/Record';

import BranchDeal from '../pages/private/schedule/deal/Deal';
import BranchPayment from '../pages/private/schedule/payment/Payment';

import SupplyOverview from '../pages/private/supply/Overview';

import Profile from '../pages/private/profile/Profile';

import ChatOverview from '../pages/private/chat/overview/Overview';

// Accounting
import AccountingOverview from '../pages/private/accounting/overview/Overview';
import PaymentProfessional from '../pages/private/accounting/paymentProfessional/PaymentProfessional';

import CreateProfessional from '../pages/private/professional/create/CreateProfessional';


// Private
// import Search from '../pages/private/search/Search';

/* Layouts */
// import PublicLayout from '../layouts/publicLayout/PublicLayout';
import DashboardLayout from '../layouts/dashboardLayout/DashboardLayout';
import FullLayout from '../layouts/fullLayout/FullLayout';

// import PrivateLayout from '../layouts/privateLayout/PrivateLayout';

export const routes: IRoute[] = [
  {
    path: '/dashboard/chat',
    name: 'Chat Page',
    component: ChatOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/supply',
    name: 'Supply Page',
    component: SupplyOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/employee',
    name: 'Employee Page',
    component: EmployeeOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/session/record/:record_id/:meet_id',
    name: 'Session Record Overview Page',
    component: SessionRecord,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/session/:meet_id',
    name: 'Session Overview Page',
    component: SessionOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/assessment/meet/:meet_id',
    name: 'Meet Overview Page',
    component: MeetOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/profile',
    name: 'Profile Overview Page',
    component: Profile,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/payment',
    name: 'Payment Overview Page',
    component: BranchPayment,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard/deal',
    name: 'Branch Deal Overview Page',
    component: BranchDeal,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/dashboard',
    name: 'Schedule Overview Page',
    component: ScheduleOverview,
    layout: DashboardLayout,
    exact: true
  },
  {
    path: '/professional/create',
    name: 'Professional Create Overview Page',
    component: CreateProfessional,
    layout: FullLayout,
    exact: true
  },
  {
    path: '/',
    name: 'Schedule Overview Page',
    component: ScheduleOverview,
    layout: DashboardLayout,
    exact: true
  }
];