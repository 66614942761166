import React, { useEffect } from 'react';
import SideMenu from '../../components/sideMenu/SideMenu';
import './DashboardLayout.css';
import { Container, HeaderDashboard, JS } from 'im-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';
import { request, ContentTypes } from '../../store/api/api';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProfessional } from '../../store/professional/ProfessionalActions';

const menu = () => {
  return (
    <ul>
      <li><a href='/'>Home</a></li>
      <li><a href='/settings'>Configuración</a></li>
    </ul>
  )
}

/*
const user = {
  account_id: 'ifzxYtmTJR8yLajYF9Wvmq',
  account_email: 'mmartinez29@gmail.com',
  account_firstname: 'Test29',
  account_lastname: 'Test29',
  account_role: 'professional'
};

JS.setSession({ user });
const session_token = JS.getSession();
console.log('session_token: ',session_token);
*/

function DashboardLayout(props: any) {
  const history = useHistory();
  const session = JS.getSession();
  const { professional } = useSelector((state: RootState) => state.default.professional);
  const dispatch = useDispatch();

  useEffect(()=> {
    if(professional===null) {
      getProfessional()
    }
  })

  async function getProfessional() {
    const { account_id } = session.account;
    const data = {
      account_id
    };
    const endpoint = `/professional/account`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ', result)
      if (result&& result.account_id) {
        const professional_data = result;
        dispatch(setProfessional(professional_data));
      } else {
        history.push('/professional/create')
      }
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="dashboard-layout">
      <HeaderDashboard sessionLinks={sessionLinks}>{}</HeaderDashboard>
      <SideMenu />
      <div className='main-wrapper'>
        <Container>
          {props.children}
        </Container>
      </div>
    </div>
  );
}

export default DashboardLayout