import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, NavigationBar, NavigationLink, JS } from 'im-ui-pack';

// import items from '../../../../store/items/ItemsReducers';
import { useSelector } from 'react-redux';

function Profile() {
  const session = JS.getSession();
  const { account_email } = session.account;
  const [profile, setProfile] = useState();
  const { professional } = useSelector((state: RootState) => state.default.professional);
  const professional_birthday = professional ? JS.dateFormat.timestampToDate(professional.professional_birthday) : null;
  const professional_birthday_year = professional ? professional_birthday.substring(0, 11) : null;
  useEffect(() => {

  });

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Perfil</H2>
          </Col>
        </Row>
      {professional ? (
        <div>
          <Row>
            <Col className='w-20'>Nombre completo</Col><Col>{professional.professional_fullname}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Email</Col><Col>{account_email}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Tel</Col><Col>{professional.professional_tel}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Fecha de nacimiento</Col><Col>{professional_birthday_year}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Domicilio</Col><Col>{professional.professional_location_address}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Ciudad</Col><Col>{professional.professional_location_city}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Provincia</Col><Col>{professional.professional_location_state}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Número de colegiado</Col><Col>{professional.professional_registration}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Especialidad</Col><Col>{professional.professional_type}</Col>
          </Row>
          {/*<Row>
            <Col className='w-20'>Seguro de responsabilidad civil</Col><Col><Link to={'/file.pdf'}>Descargar Seguro.pdf</Link></Col>
      </Row>*/}
          <Row>
            <Col className='w-20'>Años de experiencia laboral</Col><Col>{professional.professional_experience} años</Col>
          </Row>
          <Row>
            <Col className='w-20'>Camilla portatil</Col><Col>{professional.professional_portable ? 'Sí' : 'No'}</Col>
          </Row>
          <Row>
            <Col className='w-20'>Alta autónomos</Col><Col>{professional.professional_freelance ? 'Sí' : 'No'}</Col>
          </Row>
          {/*
          <Row>
            <Col className='w-20'>Documentos</Col><Col><Link to={'/file.pdf'}>Descargar Contrato Improovia.pdf</Link></Col>
          </Row>
          */}

        </div>
      ) : null
      }
      </Section>
    </div>
  );
}

export default Profile;