import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, NavigationBar, NavigationLink } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import branch_assessment_data from './../../../../store/mock/branch_assessment.json';

function BranchPayment() {
  useEffect(() => {});

  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetAmount = (meet: any) => {
    let total_amount = 0;
    meet.forEach(meetItem => {
      total_amount += parseFloat(meetItem.amount);
    })
    return total_amount;
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Cobros</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Sucursal</Th><Th>Fecha de cita</Th><Th>Fecha de pago</Th><Th>Presupuesto</Th><Th>Número de sesiones</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                branch_assessment_data.map((item: any)=> {
                  return (
                    <Tr key={item.assessment_id}>
                      <Td>{item.name}</Td>
                      <Td>10/03/2023</Td>
                      <Td>Pago a 60 días</Td>
                      <Td>€{getMeetAmount(item.meet)}</Td>
                      <Td>5</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Link to={`/dashboard/assessment/session/${item.meet_id}`}><Button className='primary' label='Adjuntar factura' action={()=>null}/></Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default BranchPayment;