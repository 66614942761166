import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import meet_data from './../../../../store/mock/meet.json';

function MeetOverview() {
 
  useEffect(() => {


  });

/*
  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }*/

  return ( 
    <div>
      <Section>
        <Row>
          <Col><H2>Cita</H2></Col>
          <Col className='align-right'>
            <Button className='primary' label='Agregar sucursal' />
          </Col>
        </Row>
        <Row>
          <Col>Encuesta: Personal de carga</Col><Col>Fecha: 01/09/2022 11:00 a 16:00 </Col>
        </Row>
        <Row>
          <Col>Sucursal: Mercadona Gracia</Col>
          <Col>Empleados: 20/200 (10% completado)</Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Fecha de inicio</Th><Th>Número de empleados</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                meet_data.map((item: any)=> {
                  return (
                    <Tr key={item.meet_id}>
                      <Td>{item.time_begin} {item.time_end}</Td>
                      <Td>{item.session.length}/{item.meet_total_session} (Incompleto)</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Link to={`/dashboard/assessment/session/${item.meet_id}`}>
                            <Button className='primary' label='Detalle' action={()=>null}/>
                          </Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default MeetOverview;