import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, Table, Thead, Tbody, Tr, Th, Td, Button, Modal, NavigationBar, NavigationLink, Notification, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import branch_assessment_data from './../../../../store/mock/branch_assessment.json';
import ModalCreateDeal from './modalApplyDeal/ModalCreateDeal';

function BranchDeal() {
  const [componentModal, setComponentModal] = useState<any|undefined>(undefined);
  const [meets, setMeets]=useState();
  const [notification, setNotification] = useState({status: false, style: '', message: ''});

  useEffect(() => {
    if(!meets) {
      getMeets();
    }
  });

  // Get branch related to the meet
  async function getMeets() {
    const data = {
      
    };
    const endpoint = `/professional/meets`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('*************** Meets filter result: ', result);
      setMeets(result);
    } catch (err) {
      console.log(err)
    }
  };

  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetAmount = (meet: any) => {
    let total_amount = 0;
    meet.forEach(meetItem => {
      total_amount += parseFloat(meetItem.amount);
    })
    return total_amount;
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }

  function showModalCreateDeal() {
    setComponentModal(<ModalCreateDeal close={()=>closeModal()} save={()=>saveModal()} />);
  }

  function saveModal() {
    console.log('Save');
    setComponentModal(null);
  }

  function closeModal() {
    setComponentModal(null);
  }

  function handleRequestSubscription(item:any) {
    console.log(item)
    setNotification({
      status: true,
      className: 'success',
      message: 'Su solicitud se ha enviado correctamente.'
    })
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Ofertas</H2>
          </Col>
        </Row>
        {(notification.status) ? (
          <Row>
            <Col>
              <Notification className={notification.className}>{notification.message}</Notification>
            </Col>
          </Row>) : null
        }
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Sucursal</Th>
                <Th>Fecha de cita</Th>
                <Th>Presupuesto</Th>
                <Th></Th>
              </Tr>
              </Thead>
              <Tbody>
                {
                  meets ? (
                    meets.map((item: any)=> {
                      const meet_sessions = JSON.parse(item.meet_sessions);
                      return (
                        <Tr key={item.meet_id}>
                          <Td>{item.branch_name}</Td>
                          <Td>{JS.meetFormat.interval(item.meet_time_start,item.meet_time_end)}</Td>
                          <Td>{meet_sessions.length}</Td>
                          <Td>
                            <div className='position-right btn-group'>
                              <Button className='primary' label='Apuntarme' action={()=>handleRequestSubscription(item)}/>
                            </div>
                          </Td>
                        </Tr>
                      )
                    })
                  ) : null

                }
              {/*
                branch_assessment_data.map((item: any)=> {
                  return (
                    <Tr key={item.assessment_id}>
                      <Td>{item.name}</Td>
                      <Td>{getMeetTime(item.meet)}</Td>
                      <Td>€{getMeetAmount(item.meet)}</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          {}
                          <Button className='primary' label='Aplicar' action={()=>showModalCreateDeal()}/>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              */}
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
      <Modal close={()=>closeModal()} component={componentModal}/>
    </div>
  );
}

export default BranchDeal;