import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Section, Row, Col, H2, NavigationBar, NavigationLink } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import ProfessionalChat from './tab/professional/Professional';
import './Overview.css';

function ChatOverview() {

  useEffect(() => {});

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Chat</H2>
            <NavigationBar>
              <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProfessionalChat />
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default ChatOverview;