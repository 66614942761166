// import mockOffices from './../mock/offices.json';

const initialState = {
  professional: null,
};

export default function professional(state = initialState, action) {
  switch (action.type) {
    case 'SET_PROFESSIONAL':
      return {
        ...state,
        professional: action.professional
      };
    case 'GET_PROFESSIONAL':
      return {
        ...state,
        professional: action.professional
      };
    default:
      return state;
  }
}
