import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
import meet_data from './../../../../store/mock/meet.json';

function SessionOverview() {
  // const { session } = meet_data[0];
  const { meet_id } = useParams<any>();
  const [records, setRecords]=useState();

  useEffect(() => {
    if(!records) {
      getRecords();
    }
  });

  // Get branch related to the meet
  async function getRecords() {
    const data = {
      "meet_id": meet_id
    };
    const endpoint = `/record/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ',result);
      setRecords(result);
    } catch (err) {
      console.log(err)
    }
  };

  /*async function getRecords() {
    const data = {
      "meet_id": meet_id
    };
    const endpoint = `/record/list`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('result: ',result);
      setRecords(result);
    } catch (err) {
      console.log(err)
    }
  };*/

  if(!records) {
    return (
      <div>
        <Section>
          <Row>
            <Col>
              <H2>Sesiones</H2>
              <NavigationBar>
                <NavigationLink href="https://admin.improovia.com/">Admin</NavigationLink>
                <NavigationLink href="/dashboard/">Citas</NavigationLink>
              </NavigationBar>
            </Col>
            <Col className='align-right'>
            </Col>
          </Row>
          <Row>
            <Col>
              Loading...
            </Col>
          </Row>
        </Section>
      </div>
    )
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Sesiones</H2>
            <NavigationBar>
              <NavigationLink href="/dashboard/">Citas</NavigationLink>
            </NavigationBar>
          </Col>
          <Col className='align-right'>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Nombre de empleado</Th><Th>Sesión</Th><Th>Estado</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
              {
                records.map((item: any)=> {
                  return (
                    <Tr key={item.meet_id}>
                      <Td>{item.employee_fullname}</Td>
                      <Td>{JS.meetFormat.interval(item.record_time_start,item.record_time_end)}</Td>
                      <Td>{JS.recordStatus.recordStatusToText(item.record_status)}</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Link to={`/dashboard/assessment/session/record/${item.record_id}/${meet_id}`}>
                            <Button className='primary' label='Ficha' action={()=>null}/>
                          </Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              }
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default SessionOverview;