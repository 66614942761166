import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, H3, H4, H5, Box, Input, Notification, Button, JS} from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
// import items from '../../../../store/items/ItemsReducers';
// import assessment_data from './../../../../store/mock/assessment.json';
import './CreateProfessional.css';
import { useHistory } from 'react-router-dom';
import es_states from '../../../../store/mock/es_states.json';

function CreateProfessional() {
  const history = useHistory();
  const session = JS.getSession();
  const { account_id, account_firstname, account_lastname, account_email } = session.account;
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const professional_fullname = `${account_firstname} ${account_lastname}`
  const experienceOption = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30];
  const timeDateToday = new Date();
  const time_date_year=timeDateToday.getFullYear();
  const time_date_month=(timeDateToday.getMonth() + 1).toString().padStart(2, "0");
  const time_date_day= timeDateToday.getDate().toString().padStart(2, "0");
  const professional_birthday = `${time_date_year}-${time_date_month}-${time_date_day}`;

  const [formData, setFormData] = useState({
    professional_fullname,
    professional_tel: '',
    professional_birthday: professional_birthday,
    professional_location_address: '',
    professional_location_city: '',
    professional_location_state: '',
    professional_registration: '',
    professional_type: 'fisioterapia',
    professional_insurance: '',
    professional_experience: '1',
    professional_portable: 'false',
    professional_freelancer: 'false'
  });

  useEffect(() => {

  });

  const dateToTimestamp = (time_date:any) => {
    const time_date_unix=time_date.getTime();
    return Math.floor(time_date_unix/1000);
  }

  async function handleSubmit(event: any) {
    event.preventDefault();
    if((formData.professional_fullname==='') ||
      (formData.professional_tel==='') ||
      (formData.professional_birthday==='') ||
      (formData.professional_location_address==='') ||
      (formData.professional_location_city==='') ||
      (formData.professional_location_state==='') ||
      (formData.professional_registration==='') ||
      (formData.professional_type==='') ||
      (formData.professional_insurance==='')) {
        setNotification({
          status: true,
          style: 'danger',
          message: 'Debe completar todos los campos.'
        });
    }
    else {
      const session = JS.getSession();
      const date_start = new Date(`${formData.professional_birthday}`);
      const date_start_timestamp = date_start.getTime()/1000;

      const data = {
        "account_id": account_id,
        "professional_fullname": formData.professional_fullname,
        "professional_status": "inactive",
        "professional_tel": formData.professional_tel,
        "professional_birthday": date_start_timestamp.toString(),
        "professional_location_address": formData.professional_location_address,
        "professional_location_city": formData.professional_location_city,
        "professional_location_state":  formData.professional_location_state,
        "professional_registration": formData.professional_registration,
        "professional_type": formData.professional_type,
        "professional_insurance": formData.professional_insurance,
        "professional_experience": formData.professional_experience,
        "professional_portable": formData.professional_portable,
        "professional_freelancer": formData.professional_freelancer
      };
      console.log('handleSubmit: data: ',data);
      const endpoint = `/professional/create`;
      try {
        const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
        const result = res.data;
        console.log('handleSubmit: result: ',result);
        history.push('/dashboard')
      } catch (err) {
        console.log(err)
      }
    }
  };

  const handleInputChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  return ( 
    <div className='wrapper-assessment'>
      <Box>
        <Section className='max-width'>
          <Row>
            <Col>
              <H3>Agregar profesional</H3>
            </Col>
          </Row>
        <form onSubmit={ e =>handleSubmit(e)}>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <div>Nombre completo</div>
              <Input type='text' name='professional_fullname' value={formData.professional_fullname} placeholder={'Nombre completo'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Email</div>
              <Input type='text' name='professional_email' value={account_email} placeholder={'Email'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Teléfono</div>
              <Input type='text' name='professional_tel' value={formData.professional_tel} placeholder={'Tel'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col className='w-30'>
              <div>Fecha de nacimiento</div>
              <input type="date" className='inp' id="start" name="professional_birthday" value={formData.professional_birthday} min="1900-01-01" max="2000-12-31" onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Dirección</div>
              <Input type='text' name='professional_location_address' value={formData.professional_location_address} placeholder={'Dirección'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Ciudad</div>
              <Input type='text' name='professional_location_city' value={formData.professional_location_city} placeholder={'Ciudad'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col className='w-40'>
              <div>Provincia</div>
              <select className='inp' name='professional_location_state' value={formData.professional_location_state} onChange={handleInputChange}>
                {
                  es_states.map((item, index)=>{
                    return (<option key={index} value={item.slug}>{item.state}</option>)
                  })
                }
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Número de colegiado</div>
              <Input type='text' name='professional_registration' value={formData.professional_registration} placeholder={'Número de colegiado'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Profesión</div>
              <select className='inp' name='professional_type' value={formData.professional_type} onChange={handleInputChange}>
                <option value='fisioterapia'>Fisioterapia</option>
                <option value='nutricion'>Nutrición</option>
                <option value='coaching'>Coaching</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>Seguro de responsabilidad civil / Número de poliza</div>
              <Input type='text' name='professional_insurance' value={formData.professional_insurance} placeholder={'Seguro de responsabilidad civil / Número de poliza'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              Experincia
              <select className='inp' name='professional_experience' value={formData.professional_experience} onChange={handleInputChange}>
                <option value={1}>1 año</option>
                {
                  experienceOption.map((item)=>{
                    return <option value={item}>{item} años</option>
                  })
                }
              </select>
            </Col>
            <Col>
              Camilla portátil
              <select className='inp' name='professional_portable' value={formData.professional_portable} onChange={handleInputChange}>
                <option value='false'>No</option>
                <option value='true'>Si</option>
              </select>
            </Col>
            <Col>
              Autónomo
              <select className='inp' name='professional_freelancer' value={formData.professional_freelancer} onChange={handleInputChange}>
                <option value='false'>No</option>
                <option value='true'>Si</option>
              </select>
            </Col>
          </Row>
          {/*
          <Row>
            <Col>Documentos</Col>
            <Col>
              <Button className='btn-secondary' action={()=>null} label='Subir'></Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <H5>Observaciones</H5>
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea placeholder='Comentario'></textarea>
            </Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          */}
          <Row>
            <Col className='align-right'>
              <button className='btn primary'>Agregar empresa</button>
             {/*<Button className='primary' label='Agregar empresa' action={()=>handleSubmit()} />*/}
            </Col>
          </Row>
        </form>
        </Section>
      </Box>
    </div>
  );
}

export default CreateProfessional;