import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Section, Row, Col, H2, Tabs, Tab, Table, Thead, Tbody, Tr, Th, Td, Button, NavigationBar, NavigationLink, JS } from 'im-ui-pack';
import { request, ContentTypes } from '../../../../store/api/api';
import { useSelector } from 'react-redux';

function BranchOverview() {
  const [meets, setMeets]=useState();
  const { professional } = useSelector((state: RootState) => state.default.professional);

  useEffect(() => {
    if(professional && !meets) {
      getMeets();
    }
  });

  // Get branch related to the meet
  async function getMeets() {
    const { professional_id } = professional;
    const data = {
      "professional_id": professional_id
    };
    const endpoint = `/professional/meets`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const result = res.data;
      console.log('*************** Meets filter result: ', result);
      setMeets(result);
    } catch (err) {
      console.log(err)
    }
  };

  const getMeetTime = (meet:any) => {
    return meet.map((meetItem)=>{
      return (
        <div>
          <div>{meetItem.time_begin}</div>
          <div>{meetItem.time_end}</div>
        </div>
      );
    });
  }

  const getMeetEmployeeSubscribers = (meet: any) => {
    let total_employee_subscribers = 0;
    meet.forEach(meetItem => {
      total_employee_subscribers += parseFloat(meetItem.employee_subscribers);
    })
    return total_employee_subscribers;
  }

  const getAssessmentSubscription = (item: any) => {
    return (
      <div>
        <div>{item.employee_subscription}/{item.employee_subscription_total}</div>
        <div>{(item.employee_subscription * 100)/item.employee_subscription_total }% Completado</div>
      </div>
    );
  }

  const getTimeBeginEnd = (item: any) => {
    return (
      <div>
        <div>{item.time_begin}</div>
        <div>{item.time_end}</div>
      </div>
    );
  }

  if (!meets) {
    return (
      <div>
        <Section>
          <Row>
            <Col>
              <H2>Citas</H2>
            </Col>
          </Row>
          <Row>
            <Col>
              Loading...
            </Col>
          </Row>
        </Section>
      </div>
    );
  }

  return ( 
    <div>
      <Section>
        <Row>
          <Col>
            <H2>Citas</H2>
          </Col>
        </Row>
        <Row>
          <Col>

            <Table>
              <Thead>
              <Tr>
                <Th>Puntos de servicio</Th><Th>Fecha de cita</Th><Th>Número de empleados</Th><Th></Th>
              </Tr>
              </Thead>
              <Tbody>
                {
                  meets ? (
                    meets.map((item: any)=> {
                      const meet_sessions = JSON.parse(item.meet_sessions);
                      return (
                        <Tr key={item.meet_id}>
                          <Td>{item.branch_name}</Td>
                          <Td>{JS.meetFormat.interval(item.meet_time_start,item.meet_time_end)}</Td>
                          <Td>{meet_sessions.length}</Td>
                          <Td>
                            <div className='position-right btn-group'>
                              <Link to={`/dashboard/assessment/session/${item.meet_id}`}>
                                <Button className='primary' label='Sesiones' action={()=>null}/>
                              </Link>
                            </div>
                          </Td>
                        </Tr>
                      )
                    })
                  ) : null

                }
              {/*
                branch_assessment_data.map((item: any)=> {
                  return (
                    <Tr key={item.assessment_id}>
                      <Td>{item.name}</Td>
                      <Td>{getMeetTime(item.meet)}</Td>
                      <Td>{getMeetEmployeeSubscribers(item.meet)}</Td>
                      <Td>
                        <div className='position-right btn-group'>
                          <Link to={`/dashboard/assessment/session/${item.meet_id}`}><Button className='primary' label='Detalle' action={()=>null}/></Link>
                        </div>
                      </Td>
                    </Tr>
                  )
                })
              */}
              </Tbody>
            </Table>

          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default BranchOverview;