import React from 'react';
import { Row, Col, Notification, Input, Button } from 'im-ui-pack';

const ModalApplyDeal = (props: any) => {
  const { close, save } = props;

  const saveModal = () => {
    save()
  }

  const closeModal = () => {
    close();
  }
 
  return (
    <div>
      <div className='modal-header'>Aplicar a esta oferta</div>
      <div className='modal-content'>
        <Row>
          <Col className='w-20'>
            Empresa
          </Col>
          <Col>
            Mercadona Gracia
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Locación:
          </Col>
          <Col>
            Carrer d'Astúries, 68, Gracia - Barcelona
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Comienza:
          </Col>
          <Col>
            14hs / Finaliza: 19hs
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Empleados
          </Col>
          <Col>
            5
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Presupuesto
          </Col>
          <Col>
            €160
          </Col>
        </Row>
        <Row>
          <Col className='w-20'>
            Contact RRHH:
          </Col>
          <Col>
            Maribel Garcia - XXXXXX
          </Col>
        </Row>
      </div>  
      <div className='modal-bottom'>
        <Button className='secondary-outline' action={()=>closeModal()} label='Cancelar'/>
        <Button className='primary' action={()=>saveModal()} label='Aplicar'/>
      </div>
    </div>
  )
}

export default ModalApplyDeal;
